
// Use this to Redirect to home page
import { useEffect } from "react"
import { navigate } from "gatsby"

const NotFoundPage = ({location}) => {
	useEffect(() => {

		function getqueryvariable(querystring)
		{
			var output = {};
			var tmpidx = 0;
			var paramname;
			var paramval;
			var i = 0;
			var paramlist = querystring.split('&');
			while (i < paramlist.length) {
				tmpidx = paramlist[i].indexOf("=");
				if (tmpidx > 0) {
					paramname = decodeURIComponent(paramlist[i].substring(0, tmpidx));
					paramval = decodeURIComponent(paramlist[i].substring(tmpidx+1));
					output[paramname] = paramval;
				}
				i++;
			}
			return output;
		}

		var pathname = location.pathname.length > 0?location.pathname.replace("mobilesite/","").substring(1):"";
		var querystring = location.search.length > 0?location.search.substring(1):"";
		if (querystring.length > 0) {
			if (querystring.substring(querystring.length-1) === "/") {
				querystring = querystring.substring(0, querystring.length-1);
			}
		}
		if (pathname.length > 0) {
			if (pathname.substring(0,2) === "m/") {
				pathname = pathname.substring(2);
			} else if (pathname.substring(0,7) === "mobile/") {
				pathname = pathname.substring(7);
			}
		}
		if (pathname === "privacy-policy-1.do") {
			navigate("/privacy");
			return;
		} else if (pathname === "search.do") {
			navigate("/products");
			return;
		} else if (pathname === "about.do") {
			navigate("/about");
			return;
		} else if (pathname === "findcontractor.do") {
			navigate("/about/contact/contractor");
			return;
		} else if (pathname === "locate.do") {
			navigate("/about/contact/dealer");
			return;
		} else if (pathname === "calculator.do") {
			navigate("/paintingtools/calculator");
			return;
		} else if (pathname === "choosepaint.do") {
			navigate("/paintingtools/choosepaint");
			return;
		} else if (pathname === "contact.do") {
			navigate("/about/contact");
			return;
		} else if (pathname === "downloads.do") {
			navigate("/");
			return;
		} else if (pathname === "faq.do") {
			navigate("/about/contact");
			return;
		} else if (pathname === "historical-timeline.do") {
			navigate("/about/history");
			return;
		} else if (pathname === "paintingtips.do") {
			navigate("/paintingtools");
			return;
		} else if (pathname === "interactive.do") {
			navigate("/paintingtools");
			return;
		} else if (pathname === "msds.do") {
			navigate("/about/contact");
			return;
		} else if (pathname === "news.do") {
			navigate("/about/history");
			return;
		} else if (pathname === "videos.do") {
			navigate("/about");
			return;
		} else if (pathname === "plant_visit.do") {
			navigate("/about/facilities");
			return;
		} else if (pathname === "colors.do") {
			const colorparam = getqueryvariable(querystring);

			// Query String mapping
			if (colorparam.hasOwnProperty("id")) {
				const colorid = parseInt(colorparam.id, 10);
				if(colorid === 16070) { navigate("/products/boysen-cool-shades-heat-reflective-colored-roof-coating"); return; }
				else if(colorid === 16071) { navigate("/products/boysen-decore-classic"); return; }
				else if(colorid === 16072) { navigate("/products/boysen-decore-perlescente"); return; }
				else if(colorid === 16073) { navigate("/products/boysen-epoxy-enamel"); return; }
				else if(colorid === 16074) { navigate("/products/boysen-knoxout-air-cleaning-paint"); return; }
				else if(colorid === 16075) { navigate("/products/boysen-marmorino-italian-marble-finish"); return; }
				else if(colorid === 16107) { navigate("/products/boysen-decore-sandino"); return; }
				else if(colorid === 16109) { navigate("/products/boysen-healthy-home-odor-less-antibacterial-paint"); return; }
				else if(colorid === 16112) { navigate("/products/titan-superflex-elastomeric-paint"); return; }
				else if(colorid === 16113) { navigate("/products/virtuoso-silk-touch-easy-clean-paint"); return; }
				else if(colorid === 1978) { navigate("/products/boysen-acqua-epoxy-acrylic-water-based-epoxy-paint"); return; }
				else if(colorid === 1982) { navigate("/products/boysen-oil-wood-stain"); return; }
				else if(colorid === 1983) { navigate("/products/boysen-permacoat-latex"); return; }
				else if(colorid === 1987) { navigate("/products/boysen-quick-drying-enamel"); return; }
				else if(colorid === 1988) { navigate("/products/boysen-roofgard-gloss-acrylic-water-based-roof-paint"); return; }
				else if(colorid === 1991) { navigate("/products/boysen-xyladecor"); return; }
			} // colorparam.hasOwnProperty("id")

			navigate("/colorcollection");
			return;
		} else if (pathname === "products.do") {
			const productparam = getqueryvariable(querystring);

			if (productparam.hasOwnProperty("category_id")) {
				const categoryid = parseInt(productparam.category_id, 10);
				if(categoryid === 432) { navigate("/products/category/industrial-coatings"); return; }
				else if(categoryid === 433) { navigate("/products/category/stains-and-colorants"); return; }
				else if(categoryid === 434) { navigate("/products/category/nitrocellulose-lacquer-based-coatings"); return; }
				else if(categoryid === 435) { navigate("/products/category/solvent-based-acrylic-paint"); return; }
				else if(categoryid === 436) { navigate("/products/category/industrial-coatings"); return; }
				else if(categoryid === 437) { navigate("/products/category/polyurethane-coatings"); return; }
				else if(categoryid === 438) { navigate("/products/category/preparation-chemicals"); return; }
				else if(categoryid === 447) { navigate("/products/category/premium-economy-paints-and-coatings"); return; }
				else if(categoryid === 5820) { navigate("/products/category/premium-acrylic-water-based-paints-and-coatings"); return; }
				else if(categoryid === 5821) { navigate("/products/category/specialty-water-based-coatings"); return; }
				else if(categoryid === 5822) { navigate("/products/category/high-performance-elastomeric-paint"); return; }
				else if(categoryid === 5823) { navigate("/products/titan-superflex-elastomeric-paint"); return; }
				else if(categoryid === 5824) { navigate("/products/category/special-effects"); return; }
				else if(categoryid === 6847) { navigate("/products/category/thinners-and-reducers"); return; }
			} else if (productparam.hasOwnProperty("group_id")) {
				const groupid = parseInt(productparam.group_id, 10);
				if(groupid === 39) { navigate("/products"); return; }
			} else if (productparam.hasOwnProperty("item_id")) {
				const itemid = parseInt(productparam.item_id, 10);
				if(itemid === 1059) { navigate("/products/boysen-healthy-home-odor-less-antibacterial-paint"); return;}
				else if(itemid === 1064) { navigate("/products/virtuoso-silk-touch-easy-clean-paint"); return;}
				else if(itemid === 1066) { navigate("/products/boysen-spar-varnish-b-58"); return;}
				else if(itemid === 1069) { navigate("/products/boysen-aquaseal-b-99"); return;}
				else if(itemid === 1077) { navigate("/products/nation-fast-dry-enamel"); return;}
				else if(itemid === 1078) { navigate("/products/titan-superflex-elastomeric-paint"); return;}
				else if(itemid === 1082) { navigate("/products/nation-dreamcoat-latex"); return;}
				else if(itemid === 1157) { navigate("/products/nation-ns-latex"); return;}
				else if(itemid === 1163) { navigate("/products/boysen-acrytex"); return;}
				else if(itemid === 15920) { navigate("/products/boysen-knoxout-air-cleaning-paint"); return;}
				else if(itemid === 21856) { navigate("/products/boysen-acrytex-cast-b-1711"); return;}
				else if(itemid === 22018) { navigate("/products/boysen-elasti-kote-premium-elastomeric-wall-covering"); return;}
				else if(itemid === 24250) { navigate("/products/boysen-decore-perlescente"); return;}
				else if(itemid === 24285) { navigate("/products/boysen-marmorino-italian-marble-finish"); return;}
				else if(itemid === 24430) { navigate("/products/boysen-cool-shades-heat-reflective-colored-roof-coating"); return;}
				else if(itemid === 25185) { navigate("/products/boysen-ecoprimer-b-105-eco-friendly-water-based-primer"); return;}
				else if(itemid === 25444) { navigate("/products/konstrukt-permaplast-k-201-high-performance-acrylic-skimcoat"); return;}
				else if(itemid === 25445) { navigate("/products/konstrukt-permaplast-k-222-interior-render"); return;}
				else if(itemid === 25446) { navigate("/products/konstrukt-permaplast-k-202-high-performance-acrylic-render"); return;}
				else if(itemid === 25447) { navigate("/products/konstrukt-durafill-k-101-gypsum-putty-and-k-103-fast-setting-gypsum-compound"); return;}
				else if(itemid === 25448) { navigate("/products/konstrukt-durafill-k-101-gypsum-putty-and-k-103-fast-setting-gypsum-compound"); return;}
				else if(itemid === 25449) { navigate("/products/konstrukt-tileworks-k-302-all-purpose-tile-adhesive-k-302-all-purpose-tile-adhesive"); return;}
				else if(itemid === 28443) { navigate("/products/hudson-timbercoat-polyurethane-water-based-wood-coating"); return;}
				else if(itemid === 32517) { navigate("/products/boysen-matte-shield-b-4600-clear-matte-coating"); return;}
				else if(itemid === 32625) { navigate("/products/boysen-acrytex-clear-coat-b-1700"); return;}
				else if(itemid === 32807) { navigate("/products/boysen-rust-off-b-330-gray-alkyd-metal-primer"); return;}
				else if(itemid === 37882) { navigate("/products/boysen-bug-off-anti-insect-paint-with-artilin"); return;}
				else if(itemid === 37884) { navigate("/products/boysen-woodprime-b-180-water-based-wood-primer"); return;}
				else if(itemid === 37888) { navigate("/products/boysen-perma-putty-b-7312-high-build-masonry-putty"); return;}
				else if(itemid === 37899) { navigate("/products/boysen-permacoat-latex"); return;}
				else if(itemid === 38308) { navigate("/products/boysen-oil-tinting-colors"); return;}
				else if(itemid === 38309) { navigate("/products/boysen-latex-colors"); return;}
				else if(itemid === 38310) { navigate("/products/boysen-automotive-lacquer-tinting-colors"); return;}
				else if(itemid === 38311) { navigate("/products/boysen-acrytex-tinting-colors"); return;}
				else if(itemid === 38453) { navigate("/products/boysen-chalk-it-water-based-chalkboard-paint"); return;}
				else if(itemid === 38454) { navigate("/products/boysen-zebra-waterbased-marking-paint"); return;}
				else if(itemid === 38455) { navigate("/products/boysen-decore-sandino"); return;}
				else if(itemid === 38456) { navigate("/products/konstrukt-lunar-series-kaiser"); return;}
				else if(itemid === 38457) { navigate("/products/konstrukt-lunar-series-kashmir"); return;}
				else if(itemid === 38458) { navigate("/products/konstrukt-lunar-series-kanyon"); return;}
				else if(itemid === 38459) { navigate("/products/konstrukt-lunar-series-kavern"); return;}
				else if(itemid === 38460) { navigate("/products/konstrukt-lunar-series-koastal"); return;}
				else if(itemid === 38461) { navigate("/products/konstrukt-lunar-series-kaza"); return;}
				else if(itemid === 38462) { navigate("/products/konstrukt-lunar-series-koral"); return;}
				else if(itemid === 38463) { navigate("/products/konstrukt-lunar-series-korona"); return;}
				else if(itemid === 38465) { navigate("/products/konstrukt-lunar-series-kohl"); return;}
				else if(itemid === 38468) { navigate("/products/boysen-acrytex-primer-b-1705"); return;}
				else if(itemid === 49615) { navigate("/products/boysen-acrytex-sealer-b-1708"); return;}
				else if(itemid === 49616) { navigate("/products/boysen-gloss-lacquer-enamel-white-b-1200"); return;}
				else if(itemid === 49617) { navigate("/products/boysen-gypsum-joint-compound-b-711"); return;}
				else if(itemid === 49618) { navigate("/products/boysen-lacquer-flo-b-1205"); return;}
				else if(itemid === 49619) { navigate("/products/boysen-natural-wood-paste-filler-b-60"); return;}
				else if(itemid === 49620) { navigate("/products/boysen-patching-compound"); return;}
				else if(itemid === 49621) { navigate("/products/konstrukt-tileworks-k-321-tile-adhesion-promoter-and-k-302-all-purpose-tile-adhesive"); return;}
				else if(itemid === 49622) { navigate("/products/nation-fine-white-skimcoat-ns-20"); return;}
				else if(itemid === 49623) { navigate("/products/nation-red-oxide-metal-primer-ns-610"); return;}
				else if(itemid === 49624) { navigate("/products/boysen-acrytex-reducer-b-1750"); return;}
				else if(itemid === 49625) { navigate("/products/konstrukt-trafficshield-k-411-ground-repair"); return;}
				else if(itemid === 49626) { navigate("/products/boysen-epoxy-reducer-b-55"); return;}
				else if(itemid === 49627) { navigate("/products/hudson-polyurethane-floor-varnish-reducer"); return;}
				else if(itemid === 49628) { navigate("/products/boysen-lacquer-thinner-b-50"); return;}
				else if(itemid === 49629) { navigate("/products/boysen-paint-thinner-b-0340"); return;}
				else if(itemid === 49630) { navigate("/products/konstrukt-trafficshield-k-441-floor-screed"); return;}
				else if(itemid === 49631) { navigate("/products/konstrukt-trafficshield-k-406-rapid-cure-self-leveling-underlayment-compound"); return;}
				else if(itemid === 49632) { navigate("/products/konstrukt-trafficshield-k-402-concrete-leveling-bond-coat"); return;}
				else if(itemid === 49633) { navigate("/products/konstrukt-k-630-join-n-seal-hybrid-polyurethane-sealant"); return;}
				else if(itemid === 909) { navigate("/products/boysen-decore-classic"); return;}
				else if(itemid === 913) { navigate("/products/boysen-wallguard-b-5715-dirt-resisting-exterior-latex-paint"); return;}
				else if(itemid === 916) { navigate("/products/boysen-roofgard-gloss-acrylic-water-based-roof-paint"); return;}
				else if(itemid === 917) { navigate("/products/boysen-permatex-b-3101-textured-water-based-paint"); return;}
				else if(itemid === 918) { navigate("/products/boysen-primeguard-water-based-metal-primer"); return;}
				else if(itemid === 919) { navigate("/products/boysen-clear-acrylic-emulsion-b-700"); return;}
				else if(itemid === 920) { navigate("/products/boysen-masonry-putty-b-7311"); return;}
				else if(itemid === 921) { navigate("/products/boysen-plexibond-b-7760-cementitious-waterproofing-system"); return;}
				else if(itemid === 922) { navigate("/products/boysen-flatwall-enamel-b-800"); return;}
				else if(itemid === 923) { navigate("/products/boysen-semi-gloss-enamel-b-200"); return;}
				else if(itemid === 924) { navigate("/products/boysen-quick-drying-enamel"); return;}
				else if(itemid === 925) { navigate("/products/boysen-schoolboard-paint-green-b-655"); return;}
				else if(itemid === 926) { navigate("/products/boysen-plasolux-glazing-putty-b-311"); return;}
				else if(itemid === 928) { navigate("/products/boysen-red-oxide-metal-primer-b-310"); return;}
				else if(itemid === 932) { navigate("/products/boysen-silver-finish-aluminum-paint-b-75"); return;}
				else if(itemid === 933) { navigate("/products/boysen-healthy-home-odor-less-antibacterial-paint"); return;}
				else if(itemid === 935) { navigate("/products/boysen-alkyd-reflectorized-traffic-paint"); return;}
				else if(itemid === 936) { navigate("/products/boysen-alkyd-traffic-paint"); return;}
				else if(itemid === 937) { navigate("/products/boysen-xyladecor"); return;}
				else if(itemid === 938) { navigate("/products/boysen-oil-wood-stain"); return;}
				else if(itemid === 939) { navigate("/products/boysen-lacquer-primer-surfacer-b-305"); return;}
				else if(itemid === 940) { navigate("/products/boysen-lacquer-spot-putty-b-306"); return;}
				else if(itemid === 942) { navigate("/products/boysen-lacquer-products"); return;}
				else if(itemid === 943) { navigate("/products/boysen-lacquer-products"); return;}
				else if(itemid === 944) { navigate("/products/boysen-lacquer-sanding-sealer-b-1254"); return;}
				else if(itemid === 945) { navigate("/products/boysen-wallguard-b-5715-dirt-resisting-exterior-latex-paint"); return;}
				else if(itemid === 946) { navigate("/products/boysen-automotive-lacquer-b-1300"); return;}
				else if(itemid === 951) { navigate("/products/boysen-epoxy-primer"); return;}
				else if(itemid === 952) { navigate("/products/boysen-epoxy-enamel"); return;}
				else if(itemid === 953) { navigate("/products/boysen-acqua-epoxy-acrylic-water-based-epoxy-paint"); return;}
				else if(itemid === 956) { navigate("/products/hudson-polyurethane-floor-varnish-topcoat-and-sealer"); return;}
				else if(itemid === 957) { navigate("/products/hudson-polyurethane-floor-varnish-topcoat-and-sealer"); return;}
				else if(itemid === 958) { navigate("/products/boysen-masonry-neutralizer-b-44"); return;}
				else if(itemid === 959) { navigate("/products/boysen-metal-etching-solution-b-71"); return;}
				else if(itemid === 960) { navigate("/products/boysen-paint-and-varnish-remover-b-141"); return;}
				else if(itemid === 961) { navigate("/products/boysen-chalk-blocker-b-7304-masonry-surface-conditioner"); return;}
				else if(itemid === 962) { navigate("/products/nation-wood-bleach-1-and-2"); return;}
			}
			navigate("/products");
			return;
		}

		navigate("/")
	}, [location])
	return null
}

export default NotFoundPage
